import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For its second consecutive year, `}<a parentName="p" {...{
        "href": "https://www.voicesummit.ai"
      }}>{`VOICE summit`}</a>{` established itself as the largest voice-tech conference in the world, with 5,000 attendees from 26 countries and top notch speakers.`}</p>
    <p>{`Lots of things got us excited during these three intense days.`}</p>
    <h2><strong parentName="h2">{`As voice is becoming the new normal in customer journeys, it is time for brands to strategize`}</strong></h2>
    <p>{`With voice shopping expected to grow from $2 billion today to `}<a parentName="p" {...{
        "href": "https://techcrunch.com/2018/03/02/voice-shopping-estimated-to-hit-40-billion-across-u-s-and-u-k-by-2022/"
      }}>{`$40+ billion by 2022`}</a>{` in the US and the UK, there is no more doubt that brands need to integrate voice to customer journeys. Companies are understanding the stakes and are now jumping on the bandwagon: a new survey revealed by `}<a parentName="p" {...{
        "href": "https://voicebot.ai"
      }}>{`Voicebot.ai`}</a>{` during the conference shows that over 60% of marketers believe voice assistant will be an important marketing channel over the next 3-5 years.`}</p>
    <p>{`Voicebot.ai also shared a framework of the 4 strategic paths commonly adopted by brands integrating voice in their operations:`}</p>
    <ul>
      <li parentName="ul">{`Players are experimenting on a narrow scope and a single effort on a single platform (either just Alexa or just Google Assistant),`}</li>
      <li parentName="ul">{`Explorers are testing waters too, but by trying multiple platforms with a wide range of features. They then dig into analytics to figure out the best course of action,`}</li>
      <li parentName="ul">{`Miners are integrating deep voice features on a narrow domain into applications they already control`}</li>
      <li parentName="ul">{`Integrators are the most committed to add voice as a new channel and to totally integrate it to the customer journey.`}</li>
    </ul>
    <h2><div className="wp-block-image"><figure className="aligncenter is-resized"><img parentName="h2" {...{
            "src": "https://i.dashbot.io/wp-home/uploads/2019/07/30215812/Screen-Shot-2019-07-29-at-11.11.38-AM.png",
            "alt": null
          }}></img></figure></div><strong parentName="h2">{`Numerous industries are generating revenues from voice`}</strong></h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/12/19/examining-consumer-purchasing-behavior-through-alexa-and-google-home/"
      }}>{`Our December survey`}</a>{` showed that users are increasingly willing to make purchases through their voice device but transactional use cases are mainly focused on ordering from Amazon and Google, as well as ordering food delivery.`}</p>
    <p>{`VOICE19 was the occasion to hear from trailblazers that are creating new purchase behaviors in their industry. Two of them really caught our attention:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.tdameritrade.com"
          }}>{`TD Ameritrade`}</a>{` overcame security and regulatory concerns and became the first financial institution to let their customers `}<a parentName="p" {...{
            "href": "https://www.amtd.com/news-and-stories/press-releases/press-release-details/2018/Trade-Without-a-Click-or-a-Tap-TD-Ameritrade-Introduces-Voice-Activated-Investing-on-Amazon-Alexa/default.aspx"
          }}>{`trade stocks with voice on Alexa`}</a>{`. Voice fits as a natural extension of their phone, internet and mobile app channels. Users can also check their accounts, get market updates, and ask market questions through the skill.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.1800flowers.com/"
          }}>{`1-8000-FLOWERS`}</a>{` took an early and strong stance on “conversational commerce.” They believe chatbots and voice assistants can “elevate the shopping experience” and drive sales thanks to in-skill purchases. This is why they make it possible for `}<a parentName="p" {...{
            "href": "https://investor.1800flowers.com/investors/news-and-events/press-releases/2019/01-30-2019-130048917"
          }}>{`customers to purchase gifts on Google Assistant with Google Express, Samsung Bixby with Samsung Pay, or Apple Business Chat with Apple Pay`}</a>{`.`}</p>
      </li>
    </ul>
    <p>{`To support the implementation of voice monetization, Amazon organized hands-on sessions where attendees could learn how to create commerce-based skills, and streamline the transactional experience with `}<a parentName="p" {...{
        "href": "https://developer.amazon.com/docs/in-skill-purchase/isp-overview.html"
      }}>{`in-skill purchases`}</a>{`.`}</p>
    <h2><strong parentName="h2">{`The challenge of discovery and user acquisition`}</strong></h2>
    <p>{`With `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/11/19/how-consumers-really-use-alexa-and-google-home-voice-assistants/"
      }}>{`the majority of smart speaker owners using less than 3 third party skills`}</a>{`, how do you make sure users find your skill/action and understand all that they can do with it?`}</p>
    <p>{`This emerged as the main challenge currently faced by the industry.`}<br parentName="p"></br>{`
`}{`Experts shared advice on how to optimize discovery:`}</p>
    <ul>
      <li parentName="ul">{`Invocation name is key: it has to be easy to say and remember and provide some context`}</li>
      <li parentName="ul">{`Existing frameworks are helpful: IntentRequest with Alexa and `}<a parentName="li" {...{
          "href": "https://developers.google.com/actions/discovery/implicit"
        }}>{`implicit invocation`}</a>{` with Google Assistant allow users to perform a task without having to utter the invocation name`}</li>
      <li parentName="ul">{`Existing channels and advertising remain a good way to get the word out to your audience. According to `}<a parentName="li" {...{
          "href": "https://www.dashbot.io/2018/11/19/how-consumers-really-use-alexa-and-google-home-voice-assistants/"
        }}>{`a survey we ran last November`}</a>{`, 43% find out about skills through social media.`}</li>
    </ul>
    <p>{`In this context, brands that are quick to establish their voice footprint will benefit from a first mover advantage.`}</p>
    <h2><strong parentName="h2">{`The need for dedicated analytics to harness the potential of this new channel`}</strong></h2>
    <p>{`Data is the new oil. And conversational interfaces are an unprecedented way to gather the richest data ever: users can say what they want, and talking to a non-human will encourage them to be very transparent about what they think of the experience and of the brand.`}</p>
    <p>{`Whether pleasant or not to hear, this direct and earnest feedback contains a wealth of information for brands.`}</p>
    <p>{`Dashbot’s CEO and co-founder `}<a parentName="p" {...{
        "href": "https://www.voicesummit.ai/agenda"
      }}>{`Arte Merritt`}</a>{` and fellow panelists shared their thoughts on how to best tap into this new but challenging data goldmine:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Traditional analytics are not designed to deal with the richness and complexity of natural language inputs or the logic of conversation flows. Brands need to equip themselves with conversational analytics solutions specifically built for this type of unstructured data.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`No conversation designer can anticipate all use cases and all variations users will use to complete actions. Conversational analytics are essential to understand and optimize the interaction with the user for a positive user experience.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In the bigger picture, conversational interfaces make it possible to instantly collect insights on customer needs that could not be unveiled on any other channel. This data can allow brands to personalize customer relationship and feed their business strategy.`}</p>
      </li>
    </ul>
    <p>{`VOICE Summit was a great event to publicize the potential of voice and accelerate its development. We are looking forward to seeing even more adoption and exciting use cases.`}</p>
    <h2><strong parentName="h2">{`About Dashbot`}</strong></h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}><em parentName="a">{`Dashbot`}</em></a>{` `}<em parentName="p">{`is a conversational analytics platform that enables enterprises and developers to increase user engagement, acquisition, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide conversation specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      